.header-main-logo {
    width: auto;
    height: 40px;
    cursor: pointer;
}
.header-main {
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 1000;
    background-color: $White-Color;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    .header-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3em;
        border-radius: 30px;
        background-color: $theme-blue;
        color: $White-Color;
        font-family: 'Manrope-Regular';
        font-size: 14px;
        line-height: 14px;
        border: none;
    }
    .header-navigation-links {
        li {
            color: $theme-subuser;
            font-weight: 700;
            font-family: 'Manrope-Regular';
            position: relative;
            cursor: pointer;
            &:hover {
                color: $theme-blue;
                &:after {
                    content: '';
                    height: 3px;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    border-radius: 50px;
                    background-color: $theme-blue;
                    margin: 0 auto;
                }
            }
        }
        .active {
            color: $theme-blue;
            &:after {
                content: '';
                height: 3px;
                width: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                border-radius: 50px;
                background-color: $theme-blue;
                margin: 0 auto;
            }
        }
        a {
            color: $theme-subuser;
            font-weight: 700;
            font-family: 'Manrope-Regular';
            cursor: pointer;
            text-decoration: none;
            &:hover {
                color: $theme-blue;
            }
        }
    }
    .count {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        font-size: 8px;
        border-radius: 50%;
        background: $theme-red;
        position: absolute;
        top: -30%;
        right: -10%;
    }
    .user-profile-container {
        cursor: pointer;
        margin-right: 1em;
        h5 {
            color: $theme-blue;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        p {
            color: #83879e;
        }
        .profile-img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            overflow: hidden;
        }
        .profile-img {
            // width:auto;
            // height:100%;
            height: 40px;
            width: 40px;
            object-fit: contain;
        }
        .profile-menu {
            top: 120%;
            right: 10%;
            background-color: $White-Color;
            width: 16em;
            z-index: 1000000;

            .company-name {
                width: 140px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .drop-arrow {
            width: 12px;
        }
    }

    .create-btn,
    .post-btn {
        padding: 0.6em 1em;
        border-radius: 0.5em;
        margin: 15px 0;
        img {
            margin-right: 1em;
        }
    }
}

.hamburger {
    height: 100%;
    .menu-img {
        height: 30%;
    }
}

// Bootstraps Overrides
.navbar {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
}
.nav-position {
    position: fixed;
    right: 0;
    height: 60px;
}

.profile-menu-content {
    height: 2.2em;
    width: auto;
    padding: 10px;
    cursor: pointer;
    color: $theme-placeholder;
    &:hover {
        background-color: $theme-light-blue;
        color: $theme-blue;
        svg > path {
            fill: $theme-blue;
        }
    }
}

.ab-menu-content {
    height: 2.2em;
    width: auto;
    padding: 10px;
    cursor: pointer;
    color: $theme-placeholder;
    &:hover {
        background-color: $theme-light-blue;
        color: $theme-blue;
        svg > path {
            stroke: $theme-blue;
        }
    }
}

.profile-menu-img {
    height: 1em;
    width: 1em;
    margin-right: 10px;
}

.logout {
    height: 1em;
    width: 1em;
    margin-right: 10px;
}

.ab-img {
    height: 1em;
    width: 1em;
    margin-right: 10px;
}

.notification-dropdown {
    top: 200%;
    right: 0%;
    width: 300px;
    height: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    background-color: $White-Color;
    overflow: hidden;

    .title {
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $theme-subuser;
        padding: 20px 10px;
    }

    .card-container {
        height: 80%;
        overflow-y: auto;
        background: #ccd2e34d;
        &::-webkit-scrollbar {
            width: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $theme-blue;
            border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $theme-blue;
        }
    }

    .view-all {
        height: 35px;
        color: $White-Color;
        background-color: $theme-blue;
    }

    .notification-card {
        cursor: pointer;
        padding: 5px;
        background-color: $White-Color;
        margin: 6px 6px 0px 6px;
        border-left: 5px solid $theme-blue;
        height: 60px;
        .notify-img {
            width: 25px;
            height: 25px;
            margin-top: 2px;
            margin-right: 10px;
        }
        .message-container {
            height: 100%;
            color: #292929;
            .content-name {
                height: 30px;
                width: calc(100% - 20px);
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
            .time {
                text-align: end;
            }
        }
        .notification-close {
            height: 10px;
            width: 10px;
            top: 5px;
            right: 5px;
            padding: 0;
            border-radius: 0;
        }
    }
}
