@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Manrope:wght@200;400;500;600;700;800&family=Poppins:wght@400;600&display=swap');
$theme-black: #0c0c0d;
$White-Color: #ffffff;
$theme-placeholder: #8780ab;
$theme-red: #ff0000;
$theme-peacock: #12b6bc;
$theme-blue: #133f7d;

.apexcharts-legend-marker {
    background-color: red !important;
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('../fonts/Manrope/Manrope-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('../fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraLight';
    src: url('../fonts/Manrope/Manrope-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Light';
    src: url('../fonts/Manrope/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('../fonts/Manrope/Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Regular';
    src: url('../fonts/Manrope/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: url('../fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for WebKit */
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

body,
html {
    width: 100%;
    height: 100%;
    background-color: $White-Color;
    scroll-behavior: smooth;
    font-family: 'Manrope', sans-serif;
}

#root {
    height: 100%;
    width: 100%;
}

p {
    margin-bottom: 0;
}

.top-0 {
    top: 38% !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: $theme-blue;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: $theme-blue;
}

a {
    text-decoration: none;
    color: white;

    :hover {
        text-decoration: none;
    }
}

.f-mh {
    font-size: 58px;
}

.f-h {
    font-size: 38px;
}

.f-fh {
    font-size: 20px;
}

.f-sh {
    font-size: 16px;
}

.f-l {
    font-size: 12px;
}

.f-ph {
    font-size: 10px;
}

.f-c {
    font-size: 14px;
}

.f-s {
    font-size: 8px;
}

.f-xs {
    font-size: 6px;
}

.f-6 {
    font-size: 6px;
}

.f-8 {
    font-size: 8px;
}

.f-10 {
    font-size: 10px;
}

.f-10-custom {
    font-size: 10px;

    @media screen and (max-width: 1260px) {
        font-size: 8px;
    }
}

.f-11 {
    font-size: 11px;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-15 {
    font-size: 15px;
}

.f-14 {
    font-size: 14px !important;
}

.f-16 {
    font-size: 16px !important;
    line-height: 25px !important;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
}

.f-18 {
    font-size: 18px;

    @media screen and (max-width: 767px) {
        font-size: 16px;
    }
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;

    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
}

.f-24 {
    font-size: 24px;

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
}

.f-26 {
    font-size: 26px;

    @media screen and (max-width: 767px) {
        font-size: 22px;
    }
}

.f-30 {
    font-size: 30px;

    @media screen and (max-width: 1200px) {
        font-size: 14px;
    }
}

.f-30_sidebaropen {
    font-size: 22px;

    @media screen and (max-width: 1200px) {
        font-size: 12px;
    }
}

.f-36 {
    font-size: 36px;

    @media screen and (max-width: 767px) {
        font-size: 34px;
    }

    @media screen and (max-width: 1024px) {
        font-size: 34px;
    }
}

.f-40 {
    font-size: 40px;
}

.f-51 {
    font-size: 51px;
    line-height: 69px;

    @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
    }
}

.f-60 {
    font-size: 60px;
}

.f-reg {
    font-family: 'Manrope-Regular';
}

.f-sem-bol {
    font-family: 'Manrope-SemiBold';
}

.f-med {
    font-family: 'Manrope-Medium';
}

.f-lig {
    font-family: 'Manrope-Light';
}

.f-ex-lig {
    font-family: 'Manrope-ExtraLight';
}

.f-ex-bol {
    font-family: 'Manrope-ExtraBold';
}

.f-bol {
    font-family: 'Manrope-Bold';
}

.w-50 {
    width: 50%;
}

.w-13 {
    width: 13%;
}

.w-17 {
    width: 17%;
}

.w-48 {
    width: 48%;
}

.w-40 {
    width: 40%;
}

.w-33 {
    width: 33%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-5 {
    width: 5%;
}

.w-7-5 {
    width: 7.5%;
}

.w-9 {
    width: 9%;
}

.w-8 {
    width: 8%;
}

.w-10 {
    width: 10%;
}

.w-11 {
    width: 11%;
}

.w-12 {
    width: 12%;
}

.w-12-5 {
    width: 12.5%;
}

.w-25 {
    width: 25%;
}

.w-65 {
    width: 65%;
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-100 {
    width: 100%;
}

.opacity-100 {
    opacity: 100;
}

.f-right {
    float: right;
}

.cursor-pointer {
    cursor: pointer;
}

.no-wrap {
    white-space: nowrap;
}

.text-theme-black {
    color: $theme-black;
}

.text-theme-red {
    color: $theme-error;
}

.text-theme-blue {
    color: $theme-blue;
}

.text-theme-gray {
    color: $theme-gray;
}

.text-theme-white {
    color: $White-Color;
}

.justify-content-left {
    justify-content: left !important;
}

.fw-6 {
    font-weight: 600;
}

.poppins {
    font-family: 'Poppins', sans-serif;
}

.gap-30 {
    gap: 30px;
}

.gap-20 {
    gap: 20px;
}

.padd-15 {
    padding: 15px 0px 0px 0px;
}

.mar-top-5 {
    margin-top: 5%;
}

.mar-right-5 {
    margin-right: 5px;

    @media screen and (max-width: 1100px) {
        margin-right: 2px;
    }
}

.mar-auto {
    margin-left: auto;
    margin-right: auto;
}

.mar-hor-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mar-right-10 {
    margin-right: 10px;

    @media screen and (max-width: 1100px) {
        margin-right: 2px;
    }
}

.cursor-na {
    cursor: not-allowed !important;
}

.social-sign-in-btn {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 0 30px;
    border: 1px solid $theme-border;
    font-family: 'Manrope-Bold';
    font-size: 14px;
    line-height: 21px;
    color: $theme-black;
    background-color: $White-Color;

    @media screen and (max-width: 769px) {
        font-size: 12px;
        width: 100%;
    }
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.divider-container {
    padding: 1em;

    .divider {
        display: inline-block;
        height: 1px;
        width: 80%;
        background-color: $theme-border;
    }
}

.pl-30 {
    padding-left: 30px;
}

.content-main {
    background-color: $theme-mercury;
    min-height: 100%;
    height: 100%;

    @media screen and (max-width: 992px) {
        padding-left: 0px;
    }
}

button {
    box-shadow: none !important;
}

.lrf-banner-img {
    background-image: url('../images/lrf-banner.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: $theme-blue;
}

.myProfileDivider {
    width: 100%;
    height: 1px;
    background-color: $theme-blue;
    margin-bottom: 1em;

    @media screen and (min-width: 993px) {
        display: none;
    }
}

.goBackBadge {
    color: $theme-gray;
    cursor: pointer;

    &:hover {
        color: $theme-blue;
    }

    @media screen and (min-width: 993px) {
        display: none;
    }
}

.smallBtn {
    max-width: 40%;
    min-width: 40%;
}

.icon {
    margin: 0 0.4em 0 0;
}

.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.header-container {
    height: 60px;
    z-index: 10000;
}

.content-container {
    display: flex;
    height: calc(100% - 60px);
    width: 100%;
}

.sidebar-container {
    flex: 0.25;
    height: 100%;
    width: 20%;
    transition: all 0.1s ease-in;
}

.sidebar-container-collapsed {
    height: 100%;
    transition: all 0.1s ease-in;
}

.page-container {
    display: flex;
    flex: 1;
    height: 100%;
}

.page-expand {
    width: calc(100% - 80px);
}

.page-shrink {
    width: 80%;
}

.fontThemeColor {
    color: $theme-blue !important;
}

.fontGray {
    color: #83879e !important;
}

.inlineTitle {
    margin-right: 0.5em;
}

.globalSubtitle {
    color: $theme-subtitle-color;
}

.my-calender {
    color: $theme-blue;
}

.gray {
    color: $theme-subuser;
}

.sectionTitle {
    color: $theme-blue;
    font-size: 22px;
}

.flexDontGrow {
    flex-grow: 0 !important;
}

.padding-1 {
    padding: 1em !important;
}

.shadow-1 {
    box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.shadow-3 {
    box-shadow:
        0 10px 20px rgba(0, 0, 0, 0.19),
        0 6px 6px rgba(0, 0, 0, 0.23);
}

.shadow-4 {
    box-shadow:
        0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-5 {
    box-shadow:
        0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22);
}

.shadow-6 {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 0px;
}

.delete-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;

    .support-link {
        color: $theme-blue;
        cursor: pointer;
    }

    .info-logo {
        height: 8px;
        width: auto;
    }

    .db-cancel-interview-btn {
        background-color: #d92d20;
    }

    .confirm-delete-box {
        position: absolute;
        background-color: $White-Color;
        width: 500px;

        @media screen and (max-width: 992px) {
            width: 70%;
            max-width: 500px;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }

        .delete-box-logo {
            width: 5em;
            height: auto;
            display: block;
            margin: 0 auto 10px auto;
        }

        .delete-box-title {
            margin-bottom: 10px;
        }

        .delete-box-title,
        .delete-box-subtitle {
            text-align: center;
        }

        .email-verification-sub {
            font-size: 18px;
        }

        .delete-box-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-style: none;
            height: 2.4em;
            width: 6em;
            color: $White-Color;
        }

        .db-delete-btn {
            background-color: #f77563;
        }

        .db-cancel-btn {
            background-color: #626262;
        }

        .db-submit-btn {
            color: $theme-blue;
            background-color: $theme-light-blue;
        }
    }

    .time-slot-container {
        width: 530px;
        height: 500px;
        background-color: $Back-ground;
        border-radius: 10px;

        .timeslot-close {
            position: absolute;
            height: 20px;
            width: 20px;
            top: 20px;
            right: 10px;
            border-radius: 50%;
            background-color: $theme-blue;
            border: none;

            .white-close {
                height: 10px;
                width: auto;
            }
        }

        .slot-card-container {
            height: 90%;
            overflow-y: auto;

            .slot-card {
                background-color: $theme-light-blue;
                border-radius: 10px;

                .slots-container {
                    display: flex;
                    flex-wrap: wrap;

                    .time-container {
                        border: 1.5px solid #becada;
                        padding: 5px;
                        color: $theme-blue;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

.email-verification-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9998;
    overflow-y: auto;

    .center-box {
        position: absolute;
        left: 30%;
        width: 70%;
        height: 100%;

        @media screen and (max-width: 992px) {
            width: 100%;
            left: 0;
        }
    }

    .email-verification-box {
        width: auto;
        background-color: $White-Color;

        .vari-close {
            position: absolute;
            right: 4px;
            top: 4px;
        }

        @media screen and (max-width: 992px) {
            width: 70%;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }

        .delete-box-logo {
            width: 5em;
            height: auto;
        }

        .email-verification-sub {
            font-size: 16px;
        }

        .delete-box-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-style: none;
            height: 2.4em;
            width: 8em;
        }

        .db-delete-btn {
            color: $theme-red;
            background-color: $theme-light-red;
        }

        .db-cancel-btn {
            color: $theme-placeholder;
            background-color: $theme-light-blue;
        }
    }
}

.input-shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.expiry-alert {
    background-color: $theme-carrot;
    color: $White-Color;
    word-spacing: 2.5px;
    text-align: center;

    .link {
        text-decoration: underline;
        text-decoration-color: $White-Color;
        cursor: pointer;
    }

    .hide {
        cursor: pointer;
        border: 1px solid $White-Color;
        border-radius: 50%;
        right: 1%;
        top: 18%;
        height: 16px;
        width: 16px;
        padding: 2px;

        img {
            height: 12px;
            width: auto;
        }
    }
}

.text-animate {
    animation-name: credit;
    animation-duration: 4s;
}

@keyframes credit {
    0% {
        left: 0px;
        top: 0px;
    }

    100% {
        left: 100%;
        top: 0px;
    }
}

.credit-box-container {
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    z-index: 1000;

    .credit-topup-box {
        position: absolute;
        background-color: $White-Color;
        top: 10%;
        right: 25%;
        padding: 20px;

        .title {
            margin-bottom: 6px;

            .btn-close {
                height: 0.4em;
                width: 0.4em;
            }
        }

        .select-credit {
            margin-right: 5px;

            .credits {
                margin: 6px 0;

                span {
                    border: 1px solid $theme-blue;
                    color: $theme-blue;
                    height: 2em;
                    width: 2.5em;
                    margin-right: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0.2em;
                    cursor: pointer;

                    &:hover {
                        background-color: $theme-blue;
                        color: $White-Color;
                    }
                }
            }
        }

        .credit-price {
            margin-left: 5px;
        }

        input {
            outline: 0;
            border-style: none;
            height: 3em;
            width: 10em;
            margin: 8px 0;
            border-radius: 0.25em;
            padding: 5px;
        }

        .buy-now {
            padding: 0.6em 2em;
            border-radius: 0.5em;
            margin: 6px auto 0 auto;
        }
    }
}

.Toastify__toast {
    width: 100%;
}

.Toastify__toast-icon {
    margin-right: 20px;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-center {
    display: flex;
    align-items: center;
}

.resumeScore-img {
    width: 118px;
    height: 38px;
}

.d-end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.f-8 {
    font-size: 8px;
}

.f-10 {
    font-size: 10px;
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-20 {
    font-size: 20px;
}

.f-26 {
    font-size: 26px;

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.f-34 {
    font-size: 34px;

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.f-46 {
    font-size: 46px;

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
}

.f-76 {
    font-size: 76px;

    @media screen and (max-width: 600px) {
        font-size: 40px;
    }
}

.blue-btn {
    border-style: none;
    background-color: $theme-blue;
    border-radius: 0.5em;
    color: $White-Color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;

    img {
        margin-right: 10px;
        height: 0.8em;
    }
}

.app-container {
    background-color: $Back-ground;
}

.product-intro {
    height: 100vh;
    position: relative;
    overflow: hidden;

    .content {
        position: absolute;
        width: 40%;
        top: 40%;
        left: 5%;

        .subtitle {
            text-align: justify;
        }

        @media screen and (max-width: 992px) {
            width: 80%;
            top: 58%;
            left: 10%;
        }

        .product-name {
            color: $theme-peacock;
        }
    }

    .slider-img {
        position: absolute;
        width: 40%;
        top: 30%;
        right: 5%;

        @media screen and (max-width: 992px) {
            top: 25%;
            width: 60%;
            right: 20%;
        }
    }

    .blue-bg {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}

.pointer {
    cursor: pointer;
}

.cstm-select-box .select__menu {
    position: absolute !important;
    top: initial !important;
    left: 0;
    bottom: 100% !important;
    z-index: 9 !important;
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}

.gradeOut {
    opacity: 0.4;
}

.w-47 {
    width: 47%;
}

.breadcrumb-item {
    cursor: pointer;
}

.success-btn {
    padding: 0.6em 2em;
    margin: 0 10px;
}

.pay-success-4 a:focus,
.pay-success-4 a:hover {
    color: #fff;
}

.text-theme-white {
    color: $White-Color;
}

.text-theme-placeholder {
    color: $theme-placeholder;
}

.Toastify__toast-container {
    z-index: 10000000 !important;
}

.text-area {
    resize: vertical !important;
}

.hidden {
    display: none;
}

.css-26l3qy-menu > div {
    max-height: 150px;
}

.text-etc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.float-right {
    float: right;
}

.circular-graph-box-p2 .CircularProgressbar {
    padding: 20px;
}

.download-sample {
    color: var(--bs-link-hover-color);
    margin-left: 10px;
}

.DateRangePicker__Date {
    width: 40px !important;
    height: 40px !important;
    padding: 2px !important;
}

.DateRangePicker__CalendarSelection--start,
.DateRangePicker__CalendarSelection--end,
.DateRangePicker__Date--is-selected,
.DateRangePicker__Date--is-highlighted {
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    border-radius: 50% !important;
    background-color: #123a75 !important;
    font-weight: 500 !important;
    color: #fff !important;
}

.DateRangePicker__CalendarSelection,
.DateRangePicker__CalendarHighlight {
    border: none !important;
    background-color: #123a75 !important;
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
}

.DateRangePicker {
    display: block !important;
    text-align: center !important;
}

.calendar-slots-container .calendar {
    padding: 10px;
}

.rmdp-panel-body li,
.rmdp-panel-body li.bg-blue,
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #123a75 !important;
}

.rmdp-week-day {
    color: #000 !important;
}

.panelist-title-center {
    align-items: center;
    justify-content: center;
}

.rmdp-panel-body span {
    font-size: 11px !important;
    font-weight: 100;
    line-height: 20px !important;
    font-family: 'Manrope-Regular';
}

.rmdp-panel {
    min-width: 100px !important;
}

.rmdp-shadow {
    box-shadow: 0 0 1px #8798ad !important;
    border: none !important;
    display: block;
    margin: 0 auto;
}

.slot-title {
    font-size: 12px;
    text-align: left !important;
    padding: 2px;
}

.slot-btn {
    line-height: 1.5;
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    position: relative;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    text-transform: none;
    will-change: transform;
    font-size: 0.6rem;
}

.time-slot-div {
    border-radius: 3px;
    box-shadow: 0 0 2px #8798ad;
    color: #fff;
    display: flex;
    margin: 5px;
    background-color: #123a75;
    position: relative;
    width: 100px;
    font-size: 12px;
}

.date-slot-div {
    background-color: #fff;
    color: #123a75;
    border-left: 2px solid #123a75;
    text-align: center;
    border-radius: 3px;
    position: relative;
    width: 120px;
    padding: 3px;
    box-shadow: 0 0 2px #8798ad;
    font-size: 12px;
    margin: 5px;
}

.time-slot-span {
    padding: 3px 5px;
}

.review-slot-deselect {
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    font-family: Arial !important;
    font-size: 16px;
    height: 12px;
    justify-content: center;
    line-height: 5px;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 52%;
    transform: translateY(-50%) rotate(45deg);
    width: 12px;
}

.slot-mbtn {
    margin-bottom: 12px;
    display: flex;
}

.overflow-yes {
    overflow: auto;
}

.top-toggle {
    top: 2px;
    margin: 0px 5px;
}

.date-time-slot-div {
    max-height: 360px;
    overflow: auto;
}

.max-wid-150 {
    max-width: 150px;
}

.c-label {
    font-family: 'Manrope-SemiBold';
    color: $theme-black !important;
}

.eye-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}

.cursor-pointer {
    cursor: pointer;
}

.common-input {
    width: 100%;
    border: none;
    padding: 1em;
    font-family: 'Manrope-Regular';
    outline: none !important;
    background-color: $White-Color;

    &::placeholder {
        color: $theme-placeholder;
    }
    &:disabled {
        background-color: #e7e7e7 !important;
        color: black !important;
    }
}

.common-input-publicLink {
    width: 100%;
    border: none;
    padding: 1em;
    font-family: 'Manrope-Regular';
    outline: none !important;
    background-color: $White-Color;

    &::placeholder {
        color: $theme-placeholder;
    }
}

.text-area {
    resize: none;
    padding: 15px;
}

.select__control {
    border-style: none !important;
}

.select__control--is-focused {
    border-style: none !important;
    border-color: white !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}

.asterisk {
    color: $theme-red;
}

.wrapper {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    align-items: center;
    justify-content: center;
    display: flex;

    .loader {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        border: 5px solid $theme-blue;
        border-top-color: #ffffff;
        box-sizing: border-box;
        background: transparent;
        animation: loading 1s linear infinite;
        margin: auto;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }

        0% {
            transform: rotate(360deg);
        }
    }
}

.cld-unavailable {
    background: #d0d0d8;
    border: 1.5px solid #d0d0d8 !important;
}

.create-jd-btn {
    float: right;
    display: inline-block;
    margin-top: -42px;
    width: 33%;
    background: #11b2b8;
    color: #fff;
    height: 42px;
    border: none;
}

.create-jd-btn-2 {
    float: right;
    display: inline-block;
    margin-top: -42px;
    width: 35%;
    background: #9b9b9b;
    color: #fff;
    height: 42px;
    border: none;
}

.jd-to-cv-match-btn {
    display: inline-block;
    width: 200px;
    background: #11b2b8;
    color: #fff;
    height: 40px;
    border: none;
    margin-top: 30px;
}

.save-field {
    background: #11b2b8;
    color: #fff;
    padding: 0px 20px;
    height: 46px;
    margin-top: 28px;
    border: none;
}

.jd-to-cv-match-btn-2 {
    display: inline-block;
    width: 200px;
    background: #626262;
    color: #fff;
    height: 40px;
    border: none;
    margin-top: 30px;
    cursor: auto !important;
}

.footer-link {
    color: #143a75 !important;
}

.create-jd-div {
    width: 62% !important;
}

.jDDataValue-container {
    background-color: #ffffff;
    padding: 10px;

    .jd-title-container {
        display: flex;
        justify-content: space-between;
        text-wrap: wrap;
        width: 100%;

        .jd-title {
            font-family: 'Manrope', sans-serif;
            font-weight: 600;
            color: '#171717';
        }

        .jd-company-name {
            font-family: 'Manrope', sans-serif;
            font-weight: 400;
            margin-bottom: 16px;
            color: '#191919';
        }

        .jd-title-divider {
            height: 20px;
            width: 1.2px;
            background-color: black;
            margin: 0 5px;
        }
    }

    .jd-logo {
        width: 149px;
        height: 61px;
        position: absolute;
        right: 10px;
        top: 10px;
        object-fit: contain;
    }
}

.jDDataValue-div {
    &::-webkit-scrollbar {
        display: none;
    }

    width: 100%;
    font-family: 'Manrope-Regular';
    margin: 0px !important;
    background: #fff;
    resize: none;
    overflow-y: auto;
    border: none;
    color: #171717;
    word-break: keep-all;

    &::-webkit-scrollbar {
        display: none;
    }

    &:focus {
        outline: none;
    }
}

.pc-progress-new-bar {
    margin: 10px 0px;
    height: 1.5em;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: #c5c5c5;

    .pc-progress-bar {
        background-image: linear-gradient(to right, #cadff5, #133f7d);
    }
}

.question-container .pc-total {
    font-size: 14px;
    margin-bottom: 10px;
    color: #204984;
}

.question-type-container {
    margin-bottom: 13px;
    display: flex;

    .question-type {
        width: 154px;
        border-radius: 3px;
        border: 0.35px solid #000;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    }
}

.question-width {
    width: calc(100% - 200px);
}

#dropdown-basic {
    background-color: #e6e9f8;
    border-style: none;
    height: 24px !important;
    width: 24px !important;
    padding: 0px !important;
}

#dropdown-basic .threedot-img {
    height: 10px !important;
    margin-top: -2px;
}

#dropdown-basic-transparent {
    background-color: transparent;
    border-style: none;
    height: 24px !important;
    width: 24px !important;
    padding: 0px !important;
}

#dropdown-basic-transparent .threedot-img {
    height: 10px !important;
    margin-top: -2px;
}

.dropdown-toggle::after {
    display: none !important;
}

.threedot-menu {
    background-color: $White-Color !important;
    border: none !important;

    .threedot-menu-content {
        height: 2.5em;
        width: 15em;
        cursor: pointer;
        color: $theme-subuser;

        &:hover {
            background-color: $theme-light-blue;
            color: $theme-blue;
            padding: 14px;
        }

        @media screen and (max-width: 992px) {
            width: 10em;
        }
    }

    .threedot-menu-content-disabled {
        background-color: #cccccc !important;
    }

    .threedot-btn {
        border-style: none;
        background-color: $theme-light-blue;
        height: 2.4em;
        width: 2.4em;

        .threedot-img {
            height: 1em;
        }
    }
}

.subscription-choose {
    float: right;
    margin: 0px 0px 10px 0px;
}

.product-status-show {
    color: red;
    font-size: 12px;
}

.contact-us-btn {
    margin: 0px 5px;
}

.delete-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0%;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;

    .common-popup-box {
        padding: 20px;
        background-color: #ffffff;
        width: 400px;
        box-shadow:
            0px 20px 24px -4px rgba(16, 24, 40, 0.1),
            0px 8px 8px -4px rgba(16, 24, 40, 0.04);
        border-radius: 12px;

        .box-logo {
            width: 4em;
        }

        .common-popup-btn-container {
            margin: 20px 0;

            .cancel-btn {
                padding: 8px 12px;
                border-radius: 5px;
                background-color: transparent;
                border: 1.5px solid #133f7d;
                color: #133f7d;
            }

            .schedule-btn {
                padding: 8px 12px;
                border-radius: 5px;
                background-color: #133f7d;
                border: 1.5px solid #133f7d;
                color: #ffffff;
            }
        }

        @media screen and (max-width: 992px) {
            width: 70%;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }

        .email-verification-sub {
            font-size: 18px;
        }

        .delete-box-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-style: none;
            height: 2.4em;
            width: 10em;
            color: $White-Color;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
        }

        .db-cancel-btn {
            background: transparent;
            border: 1px solid #d0d5dd;
            color: #000;
        }

        .db-second-btn {
            background: #039855;
            border: 1px solid #18ac00;
        }

        .db-submit-btn {
            color: $theme-blue;
            background-color: $theme-light-blue;
        }
    }

    .update-candidate-bank-popup {
        background-color: #ebedf9;
        padding: 30px;
        width: 422px;
        position: relative;
        .title {
            color: #133f7d;
            font-size: 15px;
            margin-bottom: 10px;
        }
        .save-btn {
            padding: 10px 25px;
            font-size: 12px;
            display: block;
            margin: 20px auto 0px auto;
        }
        .close-icon {
            cursor: pointer;
            width: 13px;
            height: auto;
            position: absolute;
            right: 30px;
            top: 30px;
        }
    }
}

.print-text-content {
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: #171717;
    font-family: 'Manrope-Regular';
    word-break: break-word !important;
    white-space: pre-wrap !important;
}

.print-main-container {
    background: #ffffff;
    padding: 20px;
    height: 100%;
}

.print-div-container {
    background: #ffffff;
}

.jd-logo-1 {
    width: 80px;
    height: auto;
    position: absolute;
    right: 30px;
    top: 30px;
}

.hyperlink {
    text-decoration: underline;
}

.global-btn {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.gap-10 {
    gap: 10px;
}

.link-title {
    color: #0c0c0d;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.switch-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.switch-text-gray {
    color: #aba7a7;
}

.switch-text-black {
    color: #000;
}

.blue-link-text {
    color: #133f7d !important;
    text-align: center;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 11px;
    font-style: normal;
    line-height: normal;
}

.nowrap {
    white-space: nowrap;
}

.cp {
    cursor: pointer;
}

.main-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;

    .confirm-main-box {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        background-color: $White-Color;
        width: 500px;

        @media screen and (max-width: 992px) {
            width: 70%;
        }

        @media screen and (max-width: 580px) {
            width: 90%;
        }
    }

    .time-slot-container {
        width: 530px;
        height: 500px;
        background-color: $Back-ground;
        border-radius: 10px;

        .timeslot-close {
            position: absolute;
            height: 20px;
            width: 20px;
            top: 20px;
            right: 10px;
            border-radius: 50%;
            background-color: $theme-blue;
            border: none;

            .white-close {
                height: 10px;
                width: auto;
            }
        }

        .slot-card-container {
            height: 90%;
            overflow-y: auto;

            .slot-card {
                background-color: $theme-light-blue;
                border-radius: 10px;

                .slots-container {
                    display: flex;
                    flex-wrap: wrap;

                    .time-container {
                        border: 1.5px solid #becada;
                        padding: 5px;
                        color: $theme-blue;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

.support-link {
    color: $theme-blue;
    cursor: pointer;
}

.px-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.doughnut-chart-box {
    .doughnut-chart-count {
        color: #133f7d;
        position: absolute;
        text-align: center;
        top: calc(50% - 25px);
        left: 50%;
        transform: translateX(-50%);
        width: 100%;

        .doughnut-chart-count-title {
            color: #12b6bc;
        }
    }
}

.doughnut-chart-box-position {
    .doughnut-chart-count {
        color: #133f7d;
        position: absolute;
        text-align: center;
        top: calc(50% - 25px);
        left: 50%;
        transform: translateX(-50%);
        width: 100%;

        .doughnut-chart-count-value {
            font-size: 16px;
            /* Adjust the font size if needed */
        }

        .doughnut-chart-count-title {
            color: #12b6bc;
            line-height: 12px;
            display: block;
            /* Ensure it's displayed as a block element */
        }
    }
}

.doughnut-chart-label-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 450px;
    margin: 10px auto;

    .doughnut-chart-label-box {
        display: flex;
        align-items: center;
        margin: 5px;

        .dough-chart-label-dot {
            width: 22px;
            height: 12px;
            border-radius: 6px;
            margin-right: 10px;
            color: #000;
        }
    }
}

.doughnut-chart-label-container-feedback {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 450px;
    .doughnut-chart-label-box {
        display: flex;
        align-items: center;
        margin: 5px;

        .dough-chart-label-dot {
            width: 22px;
            height: 12px;
            border-radius: 6px;
            margin-right: 10px;
            color: #000;
        }
    }
}

.doughnut-chart-label-container-openSideBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 450px;
    margin: 0px auto;

    .doughnut-chart-label-box {
        display: flex;
        align-items: center;
        margin: 5px;

        .dough-chart-label-dot {
            width: 22px;
            height: 12px;
            border-radius: 6px;
            margin-right: 10px;
            color: #000;
        }
    }
}

.dashboard-display {
    display: flex;
}

.dough-chart-label-dot-status {
    @media screen and (max-width: 1260px) {
        width: 5px;
        /* Adjust the width to make the dot smaller */
        height: 5px;
        /* Make the height equal to the width for a circle */
        border-radius: 50%;
    }

    width: 8px;
    /* Adjust the width to make the dot smaller */
    height: 8px;
    /* Make the height equal to the width for a circle */
    border-radius: 50%;
    display: inline-block;
    /* Ensure it behaves as an inline element */
    margin-right: 5px;
}

.dot-color-0 {
    background-color: #133f7d;
}

.dot-color-1 {
    background-color: #12b6bc;
}

.dot-color-2 {
    background-color: #9ed5ff;
}

.SingleChartContainer canvas,
.doubleChart canvas {
    max-height: 400px;
}

// .doughnut-chart-box.resume canvas {
//   width: 35px !important;
//   height: 450px !important;
// }
@media print {
    /* Apply styles for printing */
    html,
    body {
        width: 210mm;
        height: 297mm;
    }

    .dashboard-title-download {
        margin: 10px 0px 20px 0px !important;
        text-align: center;
        width: 100%;
    }

    .SingleChartContainer {
        margin: 15px 0px !important;
        display: block !important;
    }

    .dashboard-title-download-display {
        display: block !important;
    }

    .interview-conducted-graph,
    .doughnut-graph,
    .SChart {
        width: 100% !important;
        float: block !important;
    }

    .doughnut-chart-box canvas {
        height: 300px;
    }

    body * {
        visibility: hidden;
    }

    .InterviewStateDetails {
        display: block !important;
    }

    .InterviewStateDetails .InterviewStatNumber {
        white-space: nowrap !important;
    }

    #printable-report,
    #printable-report * {
        visibility: visible;
    }
    #printable-report {
        .col-5 {
            width: 100%;
            margin-bottom: 15px;
        }

        .mt-4 {
            margin-top: 1rem !important;
        }

        .mt-3 {
            margin-top: 0.5rem !important;
        }

        .col-7 {
            width: 100%;
        }

        .download-report {
            display: none;
        }

        .graph_card {
            padding: 9px 9px 9px 9px;
            margin-bottom: 1rem !important;
        }

        .card_box {
            padding: 9px 15px 9px 15px;
        }

        .graph_card,
        .shadow-2 {
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2) !important;
        }

        .graphDropdownDiv {
            display: none;
        }

        .valid_card_box {
            height: 94% !important;
        }

        .dashboard-display {
            display: block !important;
        }

        .doughnut-graph-position {
            width: 100% !important;
            height: auto !important;
        }

        .InterviewStateDetails {
            width: 100% !important;
            padding: 0px 0px !important;
        }

        .dashboard-chart-container {
            visibility: visible;
        }

        .print_dashboardTitle {
            display: block !important;
            text-align: center !important;
        }

        .position_stats.graph_card {
            height: 220px !important;
            margin-bottom: 1rem !important;
        }

        .doughnut-chart-count-title {
            font-size: 14px !important;
        }

        .interview_completed.graph_card {
            margin-bottom: 0.1rem;
        }

        .SingleChartContainer canvas {
            max-width: 100% !important;
            height: auto !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .break-stop {
            page-break-inside: avoid;
            break-inside: avoid;
            // break-after: page;
            padding: 5px;
            margin-top: 10px;
        }

        .content-container {
            display: flex;
            height: 100% !important;
        }

        .InterviewStateText .cursor-pointer {
            display: none;
        }

        .InterviewStateText div:first-child {
            font-size: 12px;
        }

        .TotalPostedInterviews > div {
            font-size: 12px;
        }

        .InterviewStatNumber {
            font-size: 32px;
        }
    }

    .sidebar-container-collapsed,
    .header-container,
    .sidebar-container {
        display: none !important;
    }

    .col-md-4 {
        padding: 0px 10px;
    }

    .card_box {
        height: 80px;
    }

    .valid-till-card {
        top: 8px !important;
        height: 180px !important;
    }

    .dashboard-container .valid-till-card .valid_card_box .number {
        font-size: 30px;
        line-height: 36px !important;
    }

    .dashboard-container .valid-till-card .valid_card_box .title {
        margin-top: 30px !important;
    }

    .dashContainer {
        display: block !important;
        text-align: center;
    }
}

.interview-status-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;

    .status-dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.candidate-tab {
    border: 0.5px solid;
}

.n-red {
    background-color: #f2464b !important;
}

//for Status view candidate details card view
.info-container {
    .recommended {
        background: #18ac00;
        max-width: 125px;
    }

    .inprogress {
        background: #133f7d;
        max-width: 100px;
    }

    .pending {
        background: #9b9b9b;
        max-width: 100px;
    }

    .incompletedata {
        background: #f2464b;
        max-width: 120px;
    }

    .completed {
        background: #18ac00;
        max-width: 100px;
    }

    .invitesent {
        background: #133f7d;
        max-width: 100px;
    }

    .cancelled,
    .canceled {
        background: #f2464b;
        max-width: 90px;
    }

    .networkdisconnected {
        background: #f2464b;
        max-width: 187px;
    }

    .notrecommended {
        background: #8b0000;
        max-width: 135px;
    }

    .cautiouslyrecommended {
        background: #fb6340;
        max-width: 187px;
    }

    .resumerejected {
        background: #f2464b;
        max-width: 135px;
    }

    .noshow,
    .unattended {
        background: #626262;
        max-width: 105px;
    }
}

.PositionStatus {
    .active {
        color: #18ac00;
    }

    .closed {
        color: #f2464b;
    }
}

.InterviewState {
    .completed {
        color: #f2464b;
    }

    .resumerejected {
        color: #f2464b;
    }

    .cancelled,
    .canceled {
        color: #f2464b;
    }

    .pending,
    .invited {
        color: #018d3a;
    }

    .inprogress {
        color: #e8aa4e;
    }

    .noshow,
    .unattended {
        color: #626262;
    }
}

.status-active {
    background: #ebf5eb;
    border-color: #18ac00;
    color: #18ac00;

    .dot-color {
        background-color: #18ac00;
    }
}

.status-recommended {
    // background: #ebf5eb;
    border-color: #18ac00;
    color: #18ac00;
}

.status-closed {
    background-color: #fef2f2;
    border-color: #f2464b;
    color: #f2464b;

    .dot-color {
        background-color: #f2464b;
    }
}

.status-cancelled,
.status-canceled {
    border-color: #f2464b;
    color: #f2464b;
}

.status-notrecommended {
    // background-color: #f7eded;
    border-color: #8b0000;
    color: #8b0000;

    .dot-color {
        background-color: #8b0000;
    }
}

.status-networkdisconnected {
    border-color: #f2464b;
    color: #f2464b;
}

.status-resumerejected {
    border-color: #f2464b;
    color: #f2464b;
}

.status-cautiouslyrecommended {
    border-color: #fb6340;
    color: #fb6340;
}

.status-inprogress {
    border-color: #133f7d;
    color: #133f7d;
}

.status-completed {
    border-color: #18ac00;
    color: #18ac00;
}

.status-invitesent {
    border-color: #133f7d;
    color: #133f7d;
}

.status-incompletedata {
    border-color: #f2464b;
    color: #f2464b;
}

.status-pending,
.status-invited,
.status-reinvited,
.status-noshow,
.status-unattended {
    // background-color: #f0f0f2;
    border-color: #626262;
    color: #626262;

    .dot-color {
        background-color: #626262;
    }
}

.no-record-found {
    height: calc(100vh - 235px) !important;
}

.delete-box-buttons {
    justify-content: space-evenly;
}

.pop-up-cancel {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #344054;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 10px 18px;
    width: 12em;
}

.pop-up-close {
    border-radius: 8px;
    border: 1px solid #f2464b;
    background: #f2464b;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 10px 18px;
    width: 12em;
}

.pop-up-resume {
    border-radius: 8px;
    border: 1px solid #133f7d;
    background: #133f7d;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 10px 18px;
    width: 12em;
}

.pop-up-accept {
    border-radius: 8px;
    border: 1px solid #133f7d;
    background: #133f7d;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 10px 18px;
    width: 12em;
}

.not-available {
    color: #a9a9ab;
    font-weight: 700;
    font-family: 'Manrope-Regular';
    text-decoration: none;
}

.fdb-new-btn {
    width: 25px;
}

.fdb-new-btn-info {
    float: right;
    margin: 2px;
}

.invitation-header-title {
    background-color: #ebedf9;
    height: 105px;

    p {
        font-size: 24px;
        font-weight: 700;
        line-height: 32.78px;
        text-align: center;
        color: $theme-blue;
        max-width: 341px;
        width: 100%;
    }
}

.graphDropdownDiv .select__control {
    text-align: left;
}

.textarea-hidden {
    display: none;
}

.textarea-visible {
    overflow: hidden;
}

.html-renderer-container {
    height: auto;

    .instructions-pre {
        white-space: pre-wrap;
        font-size: 12px;
        line-height: 16px;
        font-family: 'Manrope';
        font-weight: 400;
        width: 90%;
        letter-spacing: normal;
        margin-bottom: 0;
    }
}

.html-hidden {
    display: none;
}

@page {
    size: A4;
    margin: 0mm;
}

.dashboard-title-download-display {
    display: none;
}

.interview-conducted-graph {
    width: 57%;
    display: inline-block;
    float: right;
}

.doughnut-graph {
    width: 40%;
    display: inline-block;
}

.doughnut-graph-position {
    width: 55%;
    display: inline-block;
    height: 270px;
}

.doughnut-chart-box-position canvas {
    width: auto !important;
    height: 160px !important;
    margin-left: 2%;
}

.position-status-title {
    text-align: left;
    margin-bottom: 10px;
    color: #454256;
    font-family: 'Manrope-Bold';
    font-weight: 700;
}

.interview-status-title {
    text-align: left !important;
}

.interview-status-count {
    text-align: center !important;
    font-weight: 800;
}

.interview-status-posted {
    font-size: 14px;
    text-align: center !important;
}

.hr-status {
    width: 95%;
    margin: 0.5rem 0 !important;
    align-self: center;
}

.b-none {
    border: none !important;
}

.wid-100 {
    width: 100px;
}

.excel-img {
    margin-left: 10px;
}

.text-center {
    text-align: center;
}

.print-main-co {
    display: flex;
    justify-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.common-btn.disabled {
    background-color: #9b9b9b;
    border-color: #9b9b9b;
}

.instructions_content code {
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.info-container code {
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
}

.custom_question:focus,
.coding_question:focus {
    outline: none;
}

.submission .input-shadow.error,
.submission input.error {
    box-shadow: none;
    border: 2px solid #ed184f !important;
}

.Toastify__toast-body > div:last-child {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    flex: 1 1;
}

.expiry-btn {
    background-size: 10px !important;
    height: 40px !important;
    width: 150px !important;
}

.expiry-btn-txt {
    font-size: 23px !important;
    hyphens: none !important;
}

.red_txt {
    color: #ed184f !important;
}

.create-jd-div.w-100 {
    width: 100% !important;
}

.create-jd-div.w-100 {
    width: 100% !important;
}

.main-popup .confirm-main-box.fetch_box {
    max-width: 250px;
    width: 100%;
    padding: 20px 10px;
}

.fetch_text {
    color: #363333;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-top: 20px;
}

.submission .input-shadow.error,
.submission input.error {
    box-shadow: none;
    border: 2px solid #ed184f !important;
}

.Toastify__toast-body > div:last-child {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    flex: 1 1;
}

.expiry-btn {
    background-size: 10px !important;
    height: 40px !important;
    width: 150px !important;
}

.expiry-btn-txt {
    font-size: 23px !important;
    hyphens: none !important;
}

.red_txt {
    color: #ed184f !important;
}

.create-jd-div.w-100 {
    width: 100% !important;
}

.main-popup .confirm-main-box.fetch_box {
    max-width: 250px;
    width: 100%;
    padding: 20px 10px;
}

.fetch_text {
    color: #363333;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-top: 20px;
}

.custom_question_answer_container {
    .button {
        margin-top: 5px;
    }

    .select__single-value {
        overflow: visible !important;
    }
}

.interview-popup-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #1d1d1df2;
    margin-bottom: 15px;
}

.duration_img {
    max-width: 200px;
    width: 100%;
    margin: 10px auto;
}

.duration_content_container p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 400;
    color: #000000f2;
    text-align: center;
    font-family: 'Manrope';
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-left-40 {
    margin-left: 40px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-top-2 {
    margin-top: 2px !important;
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.list-title-t {
    color: #000;
}

.tabel-header {
    vertical-align: top !important;
}

table.logs_table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

th.tabel-header {
    font-weight: 600;
}

th.tabel-header,
td.logs {
    padding: 8px;
}

tr.logs:nth-child(even) {
    background-color: #f2f2f2;
}

.partition-line {
    margin: 0.25rem;
}

.delete_default_custom_btn:disabled {
    opacity: 0.7;
}

.invited-filter {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    margin: 0px !important;

    .invited-date-filter {
        margin-right: 0px;
        width: 85px !important;
        padding: 5px;
        font-size: 10px;
        border-radius: 2px;
        border: 0.5px solid #a9a9ab !important;
    }
}

.to-from-font {
    font-size: 8px;
    color: #626262;
}

.header-modal-class {
    font-size: 24px;
    font-weight: 700;
    color: #1d1d1d;
    text-align: center;
}

.question-library-main {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 50%;
    height: calc(100% - 60px);
    padding: 20px;
    box-shadow: -6px 6px 6.5px 2px rgba(0, 0, 0, 0.15);
    background: #f6f7fb;
    z-index: 100;
    .all-questions {
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .question-main {
        width: 100%;
        display: flex;
    }
    .question {
        padding: 10px 16px;
        background: #fff;
        max-height: 105px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: #626262;
        overflow-y: auto;
    }
    .question::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .question {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

.search-bar-container {
    position: relative;
    margin: 16px 0;
    display: flex;
    align-items: center;
}

.search-input {
    width: 100%;
    padding: 10px 40px 10px 16px;
    border: 1px solid #ccc;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Manrope-Regular';
    color: #4a4a4a;
}
.search-icon-container {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
    cursor: pointer;
}

.search-input::placeholder {
    color: #aaa;
}

.background-disable {
    background-color: #e7e7e7 !important;
}

.custom-add-image {
    position: absolute;
    bottom: 12.5px;
    right: 10px;
}

.code-snippet-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .snippet-head {
        width: 70%;
        border-radius: 6px 6px 0px 0px;
        background: #2f2f2f;
        padding: 6px 11px;
        color: #b4b4b4;
        font-size: 9px;
    }
    .code-block {
        height: 62px;
        padding: 5px 10px;
        width: 70%;
        background: #f2f2f2;
        color: #626262;
        font-size: 10px;
    }
    textarea.code-block {
        appearance: none;
        resize: unset;
        overflow-y: auto;
        font-size: 10px;
        outline: none;
        border: none;
    }
    .answer-reference {
        min-height: 62px;
        max-height: 650px;
        padding: 5px 10px;
        width: 100%;
        background: #f2f2f2;
        color: #626262;
        font-size: 10px;
        padding-bottom: 20px;
    }
    textarea.answer-reference {
        appearance: none;
        resize: vertical;
        overflow-y: auto;
        font-size: 10px;
        outline: none;
        border: none;
    }
    .code-block-arrow {
        position: absolute;
        left: calc(15% - 43px);
        top: 0px;
        height: 40px;
    }
}

.mt-65 {
    // margin-top: -65px;
}

.code-block-saved {
    background: #fff;
    padding: 2px 5px 3px 5px;
    color: #000;
    font-size: 10px;
    width: 80%;
}

.pre-wrap {
    white-space: pre-wrap;
}

.code-block-question {
    color: #212519;
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
}

.code-block-div {
    background-color: #f2f2f2;
    padding: 2px 5px 3px 5px;
    color: #626262;
    font-size: 10px;
    width: 80%;
    margin-left: 10px;
}

.jd-sidebar {
    width: 50% !important;
    max-width: 525px;
}

.accordion-head-main {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: black !important;
}

.accordion-head-sub {
    color: #374659;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.accordion-head-btn {
    padding: 7px 12px;
    color: #133f7d;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.accordion-head-btn.question {
    min-width: 130px;
}

.accordion-head-btn.marks {
    min-width: 105px;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg); /* Rotates arrow to the right */
}

.accordion-button::after {
    transform: rotate(-90deg); /* Rotates arrow to the right */
}

.accordion-button {
    box-shadow: none !important; /* Removes highlight when selected */
    outline: none !important; /* Removes outline */
    background-color: transparent; /* Makes background transparent */
    color: #000; /* Keeps text color black */
}

.accordion-button:focus {
    box-shadow: none !important; /* Removes focus highlight */
}

.accordion-button {
    background-color: transparent !important; /* Ensures no hover effect */
}

.accordion-button.collapsed {
    color: #000; /* Keeps text color black */
}

.custom-accordion-item {
    background: transparent;
    border: none;
}

.custom-accordion-head {
    background: white;
}

.custom-accordion-body {
    margin-top: 5px;
    background: white;
    width: 95%;
}

.accordion-collapse {
    display: flex;
    justify-content: center;
}

.top-35 {
    top: 35px;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-33 {
    margin-top: 33px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-minus-4 {
    margin-top: -4px !important;
}
.more-fields {
    color: #0d6efd;
    font-size: 12px;
    font-weight: 500;
    text-decoration-line: underline;
    margin-top: 20px;
    cursor: pointer;
    width: 80px;
}
.margin-b-3 {
    margin-bottom: 3px;
}
.gap-05 {
    gap: 5px;
}
.overflow-unset {
    overflow: unset;
}
.position-popup-btn {
    display: flex;
    width: 160px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.position-white-mode {
    border: 1px solid #d0d5dd;
    background: #fff;
}
.position-blue-mode {
    background: #133f7d;
    color: #fff;
}
.main-popup .cpp-input-container {
    width: 100% !important;
}
.applicant-code {
    color: #83879e;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px 0px 0px 0px;
}
.resume-btn {
    padding: 6px 10px;
}
.applicant-m-left {
    margin-left: 5px;
}
.applicant-white-btn {
    width: 130px;
    border: 1px solid #133f7d !important;
    color: #133f7d !important;
    background: #fff !important;
}
.applicant-btn {
    width: 130px;
    margin-right: 20px;
}

.questions-table {
    width: 96%;
    border-collapse: collapse;
    margin-top: 10px;
    margin-left: 40px;
}

.questions-table-set {
    width: 100% !important;
    margin-left: 0px !important;
}

.questions-table th,
.questions-table td {
    padding: 10px;
    border: none;
    text-align: center;
    font-size: 12px;
}

.questions-table th {
    color: #626262;
}

.questions-table th {
    background: #f2f2f2;
}

.edit-btn,
.delete-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
}

.delete-btn {
    color: #d9534f;
}

.edit-btn {
    color: #0275d8;
}

.grey-color {
    color: #626262;
}

.blue-color {
    color: #04c;
}

.questions-table tbody tr:nth-child(odd) {
    background-color: white; /* Or whatever color you prefer for even rows */
}

.questions-table tbody tr:nth-child(even) {
    background-color: #eceff9;
}

.w-185 {
    width: 185px !important;
}

.question-set-item {
    background-color: #f2f2f2;
    padding: 5px 0 5px 10px;
    width: 100%;
    min-height: 46px;
    margin-bottom: 0;
    text-wrap: auto;
    max-height: 250px;
}

.question-set-section-head {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dropdown-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-button {
    width: 134px;
    height: 28px;
    background-color: white;
    border: 1px solid #cccccc;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    color: #133f7d;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-main {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.dropdown-option {
    padding: 10px;
    cursor: pointer;
    position: relative;
    background-color: #fff;
    color: #626262;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.dropdown-sub {
    position: absolute;
    top: 0;
    left: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 20;
}

.dropdown-sub-option {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
}

.dropdown-sub-option:hover {
    background-color: #f9f9f9;
}

.dropdown-button-list {
    width: 135px;
    height: 28px;
    background-color: white;
    border: 1px solid #133f7d;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 11px;
    color: #133f7d;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-main-list {
    position: absolute;
    top: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 135px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.dropdown-option-list {
    padding: 10px;
    cursor: pointer;
    position: relative;
    background-color: #fff;
    color: #626262;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-option-list:hover {
    background-color: #e6e9f8;
}
